<template>
  <div>
    <section class="history-list ly-list">
      <div class="section-inner">
        <div class="section-cont">
          <h2>연혁관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp; <span>ABOUT</span>&nbsp;&gt;&nbsp;
            <span>연혁관리</span>
          </p>
          <!-- /.site-path -->
          <p class="total-post">
            전체 게시물 <span>{{ getDataTotalCount }}</span
            >개
          </p>
          <!-- /.total-post -->
          <search-input :searchHandler="onSearch" v-on:keyup.enter="onSearch"/>
          <!-- /.search -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
              <col class="col3" />
            </colgroup>

            <thead>
              <tr>
                <th scope="col" class="check-cell">
                  <label for="" class="hidden">전체체크</label
                  ><input
                    type="checkbox"
                    @click="check()"
                    v-model="checkAllValue"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </th>
                <th scope="col">년</th>
                <th scope="col">내용</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in getData" v-bind:key="item.id">
                <td class="check-cell">
                  <label for="" class="hidden">해당년도체크</label
                  ><input
                    type="checkbox"
                    v-model="checkedValues"
                    :value="item.id"
                  />
                  <div class="chk-icon-wrap">
                    <img
                      class="chk-icon"
                      src="@/assets/image/icon-form-checkbox.jpg"
                      alt="icon checkbox"
                    />
                  </div>
                </td>
                <td>{{ item.yyyy }}</td>
                <td class="cont-cell left">
                  <router-link
                    :to="{
                      name: 'HistoryEdit',
                      params: { id: item.id, history: item },
                    }"
                  >
                    <p v-html="item.description" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <div class="page">
            <paginate
              :pageCount="totalPages"
              :pageRange="10"
              :clickHandler="handlePageSelected"
            ></paginate>
          </div>
          <!-- /.paginate -->
          <div class="btn-wrap">
            <button class="btn-icon-del" @click="onConfirmDelete">
              <img src="@/assets/image/btn-del.png" alt="delete icon" />
              <span>선택삭제</span>
            </button>
            <router-link :to="{ name: 'HistoryCreate' }" class="btn-blue"
              >등록</router-link
            >
          </div>
          <!-- /.btn-wrap -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { mapGetters } from "vuex";
import Paginate from "../../Paginate.vue";
import SearchInput from "../../SearchInput.vue";

export default {
  components: { AppFooter, Paginate, SearchInput },
  name: "HistoryList",
  data() {
    return {
      editor: ClassicEditor,
    };
  },
  computed: {

    ...mapGetters({
      getData: "about/getHistoryList",
      getDataTotalCount: "about/getHistoryTotalCount",
    }),
  },
  created() {
    this.loadData( {
      page: 1,
      size: this.PAGE_SIZE,
    })
  },
  methods: {
    loadData( payload ) {
      this.$store.dispatch("about/reqHistoryList", payload ); 
    },
    onSearch(text) {
      
      this.searchtext = text ; 
      this.loadData( {
        page: 1,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      })
    },
    handlePageSelected(page) {
      console.log("handlePageSelected => ", page);
      this.loadData( {
        page: page,
        size: this.PAGE_SIZE,
        searchtext: this.searchtext,
      })
    },
 
    onSubmitDelete() {

      console.log("onSubmitDelete ==> ", this.checkedValues);
      if (this.checkedValues.length > 0) {
        // choice
        this.$store
          .dispatch("about/removeHistoryList", { idarr: this.checkedValues })
          .then(() => {
            this.checkedValues = [];
          });
      }
    },
  },
};
</script>
