<template>
    <div>
        EyesightShow
        <app-footer/>
    </div>
</template>

<script>
import AppFooter from '../../AppFooter.vue';

export default {
  components: { AppFooter },
  name: 'EyesightShow',
}
</script>