<template>
  <div>
    <section class="brochure-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>브로슈어 다운로드 관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp; <span>ABOUT</span>&nbsp;&gt;&nbsp;
            <span>브로슈어 다운로드 관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">브로슈어 파일 첨부</th>
                <td class="attached-file file-cell">
                  <p v-if="file">{{ file.name }}</p>
                  <p v-else class="file-empty"></p>
                  <file-select
                    v-model="file"
                    v-bind:show-del="
                      this.file != null && this.file.name != null
                    "
                  ></file-select>

                  <span>50MB 이하 업로드 (pptx / doc / xlsx / hwp / pdf)</span>
                </td>
              </tr>
              <tr>
                <th scope="row">브로슈어 파일</th>
                <td v-if="this.currentBrocchure != null">
                  <a
                    :href="this.currentBrocchure.download_url"
                    :download="this.currentBrocchure.download_url"
                    class="resume-download"
                    target="_blank"
                  >
                    {{ this.currentBrocchure.originalname }}</a
                  >
                </td>
                <td v-else><a> </a>&nbsp;&nbsp;<span></span></td>
              </tr>
            </tbody>
          </table>
          <!-- /.table-->
          <button class="btn-blue btn-right" @click="onSubmit">수정</button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template> 

<script>
import AppFooter from "../../AppFooter.vue";
import FileSelect from "../..//FileSelect.vue";
import axios from "@/api/axios-auth";

export default {
  components: { AppFooter, FileSelect },
  name: "BrochureEdit",
  data() {
    return {
      file: null,
      brochureFile: "",
      currentBrocchure: null,
    };
  },
  created() {
    console.log("BrochureEdit created ====> ");
    this.$store
      .dispatch("about/req_brochure")
      .then((res) => {
        console.log("req_brochure res => ", res.data);
        this.currentBrocchure = res.data;

        this.file = {
          name: res.data.originalname,
        };
      })
      .catch((err) => {
        console.log("error => ", err);
      });
  },
  mounted() {},
  methods: {
    onSubmit() {
      // 수정 적용
      console.log("selected File Object => ", this.file);

      // 파일을 선택한 경우
      if (this.file instanceof File) {
        // 파일을 선택한 경우

        let formData = new FormData();
        formData.append("title", "브로슈어 파일");
        formData.append("brochurefile", this.file);

        axios
          .post("/about/brochure", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            console.log("res =>", res);
            this.$alert("업로드 성공").then(() => {
              this.currentBrocchure = res.data;
            });
          })
          .catch((error) => {
            console.log("error => ", error.response.status); // status 401 인경우 비로그인 상태임.
            // console.log("error => ", error);
          });
      } else if (this.file instanceof Object) {
        console.log("brochure nothing change ");
      } else {
        console.log("brochure file delete");
        axios
          .delete("/about/brochure/1")
          .then((res) => {
            console.log("res =>", res);
            this.$alert("삭제 성공").then(() => {
              this.currentBrocchure = res.data;
            });
          })
          .catch((error) => {
            console.log("error => ", error.response.status); // status 401 인경우 비로그인 상태임.
            // console.log("error => ", error);
          });
      }
    },
  },
};
</script>

<style scoped>
/* Don't forget to hide the original file input! */
.file-select > input[type="file"] {
  display: none;
}
</style>
