<template>
  <div>
    <section class="about history-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>연혁관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp; <span>ABOUT</span>&nbsp;&gt;&nbsp;
            <span>연혁관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tbody>
              <tr>
                <th>년</th>
                <td>
                  <label for="history-year" class="hidden">연혁 년도</label>
                  <select
                    name="history-year"
                    id="history-year"
                    v-model="selectedYear"
                  >
                    <option
                      v-for="item in getHistoryYears"
                      v-bind:key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>연혁<br />내용</th>
                <td>
                  <!-- <span class="text-area text-area-l"></span> -->
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                    class="text-area"
                  ></ckeditor>
                </td>
              </tr>
            </tbody>
          </table>

          <router-link :to="{ name: 'HistoryList' }" class="btn-white"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitCreate">
            저장
          </button>
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { mapGetters } from "vuex";

export default {
  components: { AppFooter },
  name: "HistoryCreate",
  data() {
    return {
      selectedYear: new Date().getFullYear(),
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
    };
  },
  computed: {
    ...mapGetters("about", ["getHistoryYears"]),
  },
  methods: {
    onSubmitCreate() {
      console.log("등록 - validation 체크 후 ");
      console.log("yyyy => ", this.selectedYear);
      console.log("description => ", this.editorData);

      this.$store
        .dispatch("about/createHistory", {
          yyyy: this.selectedYear,
          description: this.editorData,
        })
        .then((res) => {
          console.log("history res => ", res.data);

          this.$fire({
            title: this.msg.history_create_success_title,
            text: "",
            type: "success",
            timer: 2000
          }).then(r => {
            console.log(r.value);
            this.$router.push({ name: "HistoryList" });
          });

        })
        .catch((error) => {
          console.log("about/createHistory error => ", error);
        });
    },
  },
};
</script>

<style scoped>
.ck-editor__editable_inline {
  min-height: 400px;
  max-height: 400px;
}
</style>
