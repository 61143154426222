<template>
  <div>
    <section class="eye-sight-create ly-create">
      <div class="section-inner">
        <div class="section-cont">
          <h2>눈 건강 시력지킴이 게시판</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp; <span>ABOUT</span>&nbsp;&gt;&nbsp;
            <span>눈 건강 시력지킴이 게시판</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
              </td>
            </tr>
            <tr>
              <th scope="row">대표 이미지</th>
              <td class="attached-file file-cell">
                <p v-if="fileMain">{{ fileMain.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="fileMain"></file-select>
                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>
            </tr>
            <tr>
              <th scope="row">상세 이미지</th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="files[index]"></file-select>
                  </div>
                </div>
                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'EyesightList' }" class="btn-white" tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onClickCreate">
            저장
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";
import axios from "@/api/axios-auth";

export default {
  components: { AppFooter, FileSelect },
  name: "EyesightCreate",
  data() {
    return {
      fileMain: null,
      files: [null, null, null, null, null],
      title: "",
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
    };
  },
  methods: {
    onClickCreate() {
      console.log("in onClickCreate ==>  title ", this.title);
      console.log("in onClickCreate ==>  editorData ", this.editorData);
      console.log("in onClickCreate ==>  fileMain ", this.fileMain);

      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("contents", this.editorData);

      formData.append("fileMain", this.fileMain);

      var count = 0;
      for (let attfile of this.files) {
        if (attfile != null && attfile != undefined) {
          var fieldname = "files[" + count + "]";
          formData.append(fieldname, attfile);
        }
        count++;
      }

      axios
        .post("/about/eyesight", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(res => {
          console.log("res =>", res);
          this.$alert("업로드 성공").then(() => {
            this.$router.push({ name: "EyesightList" });
          });
        })
        .catch(error => {
          // console.log('error => ', error.response );
          console.log("error => ", error.response.status); // status 401 인경우 비로그인 상태임.
          console.log("error => ", error);
        });
    },
  },
};
</script>
<style scoped></style>
