<template>
  <div>
    <section class="history-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>연혁관리</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp; <span>ABOUT</span>&nbsp;&gt;&nbsp;
            <span>연혁관리</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tbody>
              <tr>
                <th>년</th>
                <td>
                  <label for="history-year" class="hidden">연혁 년도</label>

                  <select
                    name="history-year"
                    id="history-year"
                    v-model="selectedYear"
                  >
                    <option
                      v-for="item in getHistoryYears"
                      v-bind:key="item"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>연혁<br />내용</th>
                <td>
                  <!-- <span class="text-area text-area-l"></span> -->
                  <ckeditor
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                    class="text-area"
                  ></ckeditor>
                </td>
              </tr>
            </tbody>
          </table>

          <router-link :to="{ name: 'HistoryList' }" class="btn-white">
            목록
          </router-link>

          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { mapGetters } from "vuex";

export default {
  props: ["id", "history"],
  components: { AppFooter },
  data() {
    return {
      selectedYear: this.history != null ? this.history.yyyy : "",
      editor: ClassicEditor,
      editorData: this.history != null ? this.history.description : "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },
      historyList: this.getHistoryYears,
    };
  },
  computed: {
    ...mapGetters("about", ["getHistoryYears"]),
  },
  name: "HistoryEdit",
  created() {
    // props are exposed on `this`
    console.log("props id => ", this.id);
    console.log("props history => ", this.history);
  },
  methods: {
    onSubmitModify() {
      console.log("onSubmitModify. -----");

      this.$store
        .dispatch("about/updateHistory", {
          id: this.id,
          yyyy: this.selectedYear,
          description: this.editorData,
        })
        .then((res) => {
          console.log("updateHistory res ", res.data);
          this.$alert("수정 완료");
        })
        .catch((error) => {
          console.log("updateHistory error => ", error);
          this.$alert("오류 발생.");
        });
    },
  },
};
</script>

<style scoped>
.ck-editor__editable_inline {
  min-height: 400px;
  max-height: 400px;
}
</style>
