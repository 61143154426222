<template>
  <div>
    <section class="eye-sight-edit ly-edit">
      <div class="section-inner">
        <div class="section-cont">
          <h2>눈 건강 시력지킴이 게시판</h2>
          <p class="site-path">
            <span>Home</span>&nbsp;&gt;&nbsp; <span>ABOUT</span>&nbsp;&gt;&nbsp;
            <span>눈 건강 시력지킴이 게시판</span>
          </p>
          <!-- /.site-path -->
          <table>
            <colgroup>
              <col class="col1" />
              <col class="col2" />
            </colgroup>
            <tr>
              <th scope="row">
                <label for="title">제목</label>
              </th>
              <td>
                <input type="text" id="title" name="title" v-model="title" />
              </td>
            </tr>
            <tr>
              <th scope="row">내용</th>
              <td>
                <ckeditor
                  :editor="editor"
                  v-model="editorData"
                  :config="editorConfig"
                  class="text-area"
                ></ckeditor>
                <!-- <span class="text-area">
                </span> -->
              </td>
            </tr>
            <tr>
              <th scope="row">대표 이미지</th>
              <td class="attached-file file-cell">
                <p v-if="fileMain">{{ fileMain.name }}</p>
                <p v-else class="file-empty"></p>
                <file-select v-model="fileMain" v-bind:show-del=" this.fileMain != null && this.fileMain.name != null "></file-select>
                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>
            </tr>
            <tr>
              <th scope="row">상세 이미지</th>
              <td class="file-cell">
                <div class="attached-file-wrap">
                  <div
                    class="attached-file"
                    v-for="(file, index) in files"
                    v-bind:key="index"
                  >
                    <p v-if="file">{{ file.name }}</p>
                    <p v-else class="file-empty"></p>
                    <file-select v-model="files[index]" v-bind:show-del=" file != null && file.name != null "></file-select>
                  </div>
                </div>

                <span>10MB 이하 업로드 (jpg / png / gif / bmp)</span>
              </td>
            </tr>
          </table>
          <!-- /.table-->
          <router-link :to="{ name: 'EyesightList' }" class="btn-white" tag="a"
            >목록</router-link
          >
          <button class="btn-blue btn-right" @click="onSubmitModify">
            수정
          </button>
          <!-- /.btn -->
        </div>
        <app-footer />
      </div>
    </section>
  </div>
</template>

<script>
import AppFooter from "../../AppFooter.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FileSelect from "../..//FileSelect.vue";
import attachments_update_mixin from "../../../mixins/attachments_update_mixin";

export default {
  props: ["id", "item"],
  components: { AppFooter, FileSelect },
  name: "EyesightEdit",
  mixins: [attachments_update_mixin],
  data() {
    return {
      fileMain: null, 
      files: [null, null, null, null, null] ,

      title: this.item != null ? this.item.title : "",

      editor: ClassicEditor,
      editorData: this.item != null ? this.item.contents : "",
      editorConfig: {
        // The configuration of the editor.
        toolbar: [],
      },

    };
  },
  created() {
    console.log("props id => ", this.id);
    console.log("props eyesight => ", this.item);

    this.title        = this.item.title ;
    this.attachments  = this.item.attachments ; 

    this.init_attachments_set(); 
    // var index = 0 ; 
    // for( var el of this.attachments ) {
    //   if( el.type == 'main') {
    //     this.fileMain = {
    //       name: el.originalname ,
    //       filename: el.filename 
    //     }
    //   }
    //   else {
    //     this.files[index++] = {
    //       name: el.originalname ,
    //       filename: el.filename 
    //     }
    //   }
    // }

  },
  methods: {

    onSubmitModify() {
      console.log("onSubmitModify. -----");

      // 텍스트 수정요청은 요청. 
      var payload = {
        id: this.item.id , 
        title: this.title , 
        contents : this.editorData
      }

      console.log('payload => ', payload ); 
      this.$store.dispatch("about/req_update_eyesight",  payload  )
      .then( () => {
        this.onSubmitModifyFiles("eyesight");

        // this.$alert("수정하였습니다.").then( () => {
        //     // this.$router.push({ name: "EyesightList" });
        //     this.onSubmitModifyFiles();
        // });
      })
      .catch((error) => {
        console.log("error => ", error);
      });


    },


  },
};
</script>

<style scoped>
.ck-editor__editable_inline {
  min-height: 400px;
  /* max-height: 400px; */
}

/* .ck.ck-editor {
    min-height: 200px !important;
    max-height: 400px !important;
} */
</style>
 